
import store from "@/store";
import { defineComponent } from 'vue';
import { Button, Searchbar, Table} from '@/ui/index';
import { ClientController } from '@/controller/';

export default defineComponent({
    name: "ClientList",
    components: { Button, Searchbar, Table },
    data() {
        return {    
            store,
            clientTableData: {
                thFields: [{title: this.$t("client.number"), property: 'number', type: 'string', clickAction: "openDetail" }, { title: this.$t("client.name"), property: "custName", type: "string", clickAction: "openDetail"}, { title: this.$t("client.active"), property:'active', type: 'boolean', clickAction: "toggleActiveState"}],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }, { name: "editClient", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]

            },
            clients: [] as Array<any>,
            searchInput: ""
        }
    },
    created(){
        this.handleGetClients();
    },
    computed: {
        filteredClients() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.clients.forEach((client : any) => {
                    JSON.stringify(client.custName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(client);
                    !filtered.includes(client) && JSON.stringify(client.number).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(client);
                })
            } else {
                filtered = this.clients;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetClients(){
            const res = await ClientController.fetchClients();
            if(!res.error){
                this.clients = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break
                case "editClient": 
                    this.handleEdit(item.uid);
                    break
                case "confirmDelete":
                    this.handleConfirmDelete(item);
                    break
                case "toggleActiveState":
                    this.toggleActiveState(item)
                    break                    
            }
        },
        handleShowCreateForm(){
            this.$router.push('/administration/client/form/0');
        },
        handleOpenDetailView(uid : number) {
            this.$router.push("/administration/client/detail/" + uid);
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/client/form/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteClientText', { name: item.custName }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async toggleActiveState(item : any) {
            const postData = {
                'uid': item.uid,
                'status': !item.active
            }
            const res = await ClientController.toggleClient(postData);
            if (res.client) {
                    //workaround to have reactive list
                this.clients = [];
                this.$nextTick(() => {
                    this.clients = ClientController.getClients().items
                });
            }
        },        
        async handleDelete(uid : number) {
            const res = await ClientController.deleteClient(uid);
            if (!res.error) {
                this.clients = this.clients.filter(function(item : any) {
                   return item.uid != res.client.uid;
                });
                this.$notify(this.$t("success.clientDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }

})
